import React from 'react'
import { Link } from 'gatsby'
import styles from './teAyudamos.module.scss'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import Email from '../../assets/email.svg'
import PhoneNumberLink from '../PhoneNumberLink/phonenumberlink'
import { t } from '../../i18n'
import telephones from '../../../specific/constants/telephones'

export default function AtencionAlCliente() {
  return (
    <React.Fragment>
      <div className={styles.side_bar_title}>
        <FormattedMessage id="contacto.servicios.atencion" />
      </div>
      <div className={styles.side_bar_body}>
        <div className={styles.icon_button_container}>
          <FontAwesomeIcon icon={faMobileAlt} className={styles.icon_button} />
        </div>
        <div className={styles.info_wrapper}>
          <div className={styles.text_bold}>
            {telephones.map((elem) => (
              <div key={elem.id}>
                <PhoneNumberLink
                  telephone={elem.telephone}
                  location={elem.location}
                />
              </div>
            ))}
          </div>
          <div>
            <FormattedMessage id="preferences.horario1" />
          </div>
          <div>
            <FormattedMessage id="preferences.horario2" />
          </div>
        </div>
      </div>
      <div className={styles.side_bar_body}>
        <div className={styles.icon_button_container}>
          <Email className={styles.icon_button} />
        </div>
        <div className={styles.info_wrapper}>
          <Link to={t('contacto')} className={styles.text_underlined}>
            <FormattedMessage id="contacto.title" />
          </Link>
        </div>
      </div>
    </React.Fragment>
  )
}
